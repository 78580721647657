html {
  scroll-behavior: smooth;
}

body {
  background-color: #000;
  color: #fff;
  font-family: "Poppins", sans-serif;
}

span {
  word-wrap: break-word;
}

img {
  width: 100%;
}

a {
  cursor: pointer;
}

.font-title {
  font-family: "IBM Plex Mono", monospace;
}

.text-gradient {
  background: linear-gradient(90deg, #ff12d5 0%, #2c15ff 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
}

.btn-primary {
  display: inline-flex;
  padding: 20px 30px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background: linear-gradient(90deg, #ff12d5 0%, #2c15ff 100%);
  transition: all 0.3s linear;
}

.btn-primary:hover {
  opacity: 0.7;
}

.bg-gradient {
  background: linear-gradient(90deg, #ff12d5 0%, #2c15ff 100%);
}

.btn-secondary {
  display: inline-flex;
  padding: 14px 28px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  background: rgba(0, 0, 0);
  transition: all 0.3s linear;
}

.btn-secondary:hover {
  background: linear-gradient(90deg, #ff12d5 0%, #2c15ff 100%);
}

.App {
  overflow: hidden;
  min-height: 100vh;
}

.marquee-container {
  height: 60px;
  overflow: hidden;
  line-height: 60px;
}

.marquee-container .marquee {
  top: 0;
  left: 100%;
  width: 100%;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  animation: marquee 30s linear infinite;
}

.marquee-container .marquee2 {
  animation-delay: 15s;
}
.marquee-container b {
  padding-left: 10px;
}

@keyframes marquee {
  0% {
    left: 100%;
  }
  100% {
    left: -100%;
  }
}

@media screen and (max-width: 768px) {
  .marquee-container .marquee {
    overflow: unset;
  }
}
